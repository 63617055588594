package app.megachat.website.about

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp
import app.megachat.client.ui.design.components.Scaffold
import app.megachat.client.ui.design.components.Text
import app.megachat.client.ui.design.components.TopAppBar
import app.megachat.client.ui.design.components.button.IconButton
import app.megachat.client.ui.design.theme.Dimensions.Gap100
import app.megachat.client.ui.design.theme.Dimensions.Gap200
import app.megachat.client.ui.design.theme.Dimensions.Gap50
import app.megachat.client.ui.design.theme.Icons
import app.megachat.client.ui.design.theme.defaultBackground
import app.megachat.client.ui.design.util.safeDrawingWithoutIme
import app.megachat.website.about.About.Event
import app.megachat.website.about.About.State

@Composable
internal fun AboutContent(
  state: State,
  modifier: Modifier = Modifier,
) {
  Scaffold(
    modifier = modifier
      .defaultBackground()
      .windowInsetsPadding(WindowInsets.safeDrawingWithoutIme),
    topBar = {
      TopAppBar(
        title = {
          Text(
            text = "About" // TODO: localize
          )
        },
        navigationIcon = {
          IconButton(
            painter = Icons.Navigation.Back,
            onClick = { state.send(Event.GoBack) },
          )
        }
      )
    }
  ) { contentPadding ->
      Column(
        modifier = Modifier
          .padding(contentPadding)
          .padding(Gap100),
      ) {
        Text("Email:", fontSize = 20.sp, fontWeight = FontWeight.W600)
        Spacer(Modifier.height(Gap50))
        SelectionContainer {
          Text("about@megachat.app")
        }

        Spacer(Modifier.height(Gap200))

        Text("Address:", fontSize = 20.sp, fontWeight = FontWeight.W600)
        Spacer(Modifier.height(Gap50))
        SelectionContainer {
          Column {
            Text("MEGA CHAT, LLC")
            Text("120 Newport Center Drive,")
            Text("Newport Beach, CA 92660")
            Text("U.S.A.")
          }
        }
    }
  }
}
