package app.megachat.client.ui.design.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.text.intl.Locale
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.format.Padding
import kotlinx.datetime.format.char
import kotlinx.datetime.toLocalDateTime

val LocalDateTimeFormatter = staticCompositionLocalOf<DateTimeFormatter> {
  error("No DateTimeFormatter provided")
}

@Composable
internal fun rememberDateTimeFormatter(): DateTimeFormatter {
  return remember {
    // TODO: locale and timezone could change!
    DateTimeFormatter(Locale.current, TimeZone.currentSystemDefault(), Clock.System)
  }
}

class DateTimeFormatter internal constructor(
  private val locale: Locale,
  private val timeZone: TimeZone,
  private val clock: Clock,
) {
  // TODO

  fun messageCreatedAt(instant: Instant) : String {
    return instant.toLocalDateTime(timeZone).format(dateTimeFormat)
  }

  companion object {

    // TODO: localize!
    val dateTimeFormat = LocalDateTime.Format {
      amPmHour()
      char(':')
      minute()
      char(' ')
      amPmMarker(am = "AM", pm = "PM")
      chars(" on ")
      monthNumber(padding = Padding.SPACE)
      char('/')
      dayOfMonth()
    }
  }
}
