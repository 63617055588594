package app.megachat.client.ui.core.circuit

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.AnimatedContentTransitionScope.SlideDirection
import androidx.compose.animation.EnterTransition
import androidx.compose.animation.ExitTransition
import androidx.compose.animation.core.EaseOutExpo
import androidx.compose.animation.core.tween
import androidx.compose.animation.fadeIn
import androidx.compose.animation.togetherWith
import androidx.compose.foundation.layout.Box
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clipToBounds
import androidx.compose.ui.unit.IntOffset
import app.megachat.client.ui.core.util.tapToDismissKeyboard
import com.slack.circuit.backstack.NavArgument
import kotlinx.collections.immutable.ImmutableList
import com.slack.circuit.backstack.NavDecoration as CircuitNavDecoration

internal class OpaqueNavDecoration : CircuitNavDecoration {

  @Composable
  override fun <T : NavArgument> DecoratedContent(
    args: ImmutableList<T>,
    backStackDepth: Int,
    modifier: Modifier,
    content: @Composable (T) -> Unit,
  ) {
    AnimatedContent(
      label = "OpaqueNavDecoration",
      targetState = args,
      modifier = modifier,
      contentKey = List<*>::first,
      transitionSpec = {
        val inCommon = initialState.asReversed().asSequence()
          .zip(targetState.asReversed().asSequence())
          .takeWhile { it.first == it.second }
          .count()

        when {
          inCommon == 0 -> // replace root:
            fadeIn(fadeInAnimationSpec) togetherWith ExitTransition.KeepUntilTransitionsFinished

          targetState.size > inCommon -> // push:
              slideIntoContainer(SlideDirection.Start, slideInAnimationSpec) togetherWith
                ExitTransition.KeepUntilTransitionsFinished

          else -> // pop:
            (EnterTransition.None togetherWith
                slideOutOfContainer(SlideDirection.End, slideOutAnimationSpec))
              .apply { targetContentZIndex = -1f }
        }
      },
    ) {
      Box(
        modifier = Modifier
          .tapToDismissKeyboard()
          .clipToBounds(),
        propagateMinConstraints = true,
      ) {
        content(it.first())
      }
    }
  }

  companion object {
    private val fadeInAnimationSpec = tween<Float>(
      durationMillis = 350,
      easing = EaseOutExpo,
    )

    private val slideInAnimationSpec = tween<IntOffset>(
      durationMillis = 250,
      easing = EaseOutExpo,
    )

    private val slideOutAnimationSpec = tween<IntOffset>(
      durationMillis = 500,
      easing = EaseOutExpo,
    )
  }
}
