package app.megachat.client.ui.design.components

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.unit.sp
import app.megachat.client.ui.design.theme.Dimensions.Gap50
import app.megachat.client.ui.design.theme.Dimensions.TopAppBarHeight

// Similar to material TopAppBar, but this one doesn't consume the taps (e.g. no Surface).
@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun TopAppBar(
  title: @Composable () -> Unit,
  modifier: Modifier = Modifier,
  navigationIcon: @Composable (() -> Unit)? = null,
  contentPadding: PaddingValues = DefaultContentPadding,
  actions: @Composable RowScope.() -> Unit = {},
) {
  Row(
    modifier = modifier
      .windowInsetsPadding(TopAppBarDefaults.windowInsets)
      .height(TopAppBarHeight)
      .padding(contentPadding),
    verticalAlignment = Alignment.CenterVertically,
  ) {
    navigationIcon?.invoke()

    val mergedStyle = LocalTextStyle.current.merge(TitleTextStyle)
    Box(
      modifier = Modifier.weight(1f)
    ) {
      CompositionLocalProvider(
        LocalTextStyle provides mergedStyle,
      ) {
        title()
      }
    }
    actions()
  }
}

private val DefaultContentPadding = PaddingValues(start = Gap50, end = Gap50)

private val TitleTextStyle = TextStyle(
  fontSize = 22.sp,
)
