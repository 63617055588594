package app.megachat.client.ui.design.util

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableIntStateOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import app.megachat.shared.base.util.applyIf

/**
 * Similar to the standard [androidx.compose.runtime.rememberUpdatedState] method,
 * but the value is updated only when [predicate] is true.
 */
@Composable
fun <T> rememberUpdatedStateIf(predicate: Boolean, newValue: T): State<T> =
  remember { mutableStateOf(newValue) }
    .applyIf(predicate) { value = newValue }

@Composable
fun <T> rememberUpdatedStateIfNotNull(value: T): State<T> =
  rememberUpdatedStateIf(predicate = value != null, newValue = value)

/**
 * Similar to [rememberUpdatedStateIf], but allows first update when predicate turns false
 */
@Composable
fun <T> rememberUpdatedStateIffy(predicate: Boolean, newValue: T): State<T> =
  remember { mutableStateOf(newValue) }
    .also {
      var count by remember { mutableIntStateOf(1) }
      if (predicate) {
        count = 1
        it.value = newValue
      } else if (--count >= 0) {
        it.value = newValue
      }
    }
