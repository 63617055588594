package app.megachat.client.ui.design.user.boid

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.MutableTransitionState
import androidx.compose.animation.core.tween
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Box
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalInspectionMode
import app.megachat.client.ui.design.theme.ripple
import app.megachat.client.ui.design.user.UserSummary
import app.megachat.client.ui.design.user.avatar.UserAvatar

@Composable
internal fun UserBoid(
  boidState: UserBoidsState.UserBoid,
  user: UserSummary,
  onClick: () -> Unit,
  modifier: Modifier = Modifier,
) {
  Box(
    modifier = modifier,
    propagateMinConstraints = true,
  ) {
    val isAvatarVisibleInitially = LocalInspectionMode.current
    val isAvatarVisible = remember { MutableTransitionState(isAvatarVisibleInitially) }
      .apply { targetState = boidState.showAvatar }

    AnimatedVisibility(
      visibleState = isAvatarVisible,
      enter = fadeIn(animationSpec = tween(500)),
      exit = fadeOut(animationSpec = tween(1000)),
      label = "avatar",
    ) {
      UserAvatar(
        modifier = Modifier
          .clickable(
            onClick = onClick,
            interactionSource = remember { MutableInteractionSource() },
            indication = ripple(
              bounded = false,
              radius = boidState.radius * 1.5f,
              color = Color.White,
            ),
          ),
        user = user,
      )
    }
  }
}
