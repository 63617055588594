package app.megachat.client.ui.design.util

import androidx.compose.material3.SnackbarDuration
import androidx.compose.material3.SnackbarResult
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.remember
import app.megachat.client.ui.design.material.snackbar.SnackbarHostState
import app.megachat.client.ui.design.material.snackbar.SnackbarVisualsImpl
import app.megachat.shared.base.util.lazyPub

@Composable
fun rememberSnackbarState(): SnackbarState {
  return remember { SnackbarState() }
}

@Stable
class SnackbarState internal constructor() {
  enum class Duration {
    Short,
    Long,
    Indefinite,
  }

  enum class Result {
    Dismissed,
    ActionPerformed,
  }

  suspend fun show(
    message: String,
    actionLabel: String? = null,
    duration: Duration = Duration.Short,
    style: SnackbarStyle,
  ): Result =
    snackbarHostState.showSnackbar(
      visuals = SnackbarVisualsImpl(
        actionLabel = actionLabel,
        duration = when (duration) {
          Duration.Short -> SnackbarDuration.Short
          Duration.Long -> SnackbarDuration.Long
          Duration.Indefinite -> SnackbarDuration.Indefinite
        },
        message = message,
        withDismissAction = false, // TODO
      ),
      style = style,
    ).let {
      when (it) {
        SnackbarResult.Dismissed -> Result.Dismissed
        SnackbarResult.ActionPerformed -> Result.ActionPerformed
      }
    }

  internal val snackbarHostState = SnackbarHostState()

  companion object {
    val EMPTY by lazyPub {
      SnackbarState()
    }
  }
}
