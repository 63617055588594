package app.megachat.client.ui.design.components.button

import androidx.compose.material3.LocalMinimumInteractiveComponentSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.isUnspecified
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.unit.Dp
import app.megachat.client.ui.design.components.Icon
import app.megachat.client.ui.design.theme.AppTheme
import app.megachat.client.ui.design.util.ovalShadow
import app.megachat.client.ui.design.util.progressBorder
import androidx.compose.material3.IconButton as MaterialIconButton

@Composable
fun IconButton(
  onClick: () -> Unit,
  modifier: Modifier = Modifier,
  enabled: Boolean = true,
  content: @Composable () -> Unit,
) {
  MaterialIconButton(
    onClick = onClick,
    modifier = modifier,
    enabled = enabled,
    content = content,
  )
}

@Composable
fun IconButton(
  painter: Painter,
  onClick: () -> Unit,
  modifier: Modifier = Modifier,
  progress: Color = Color.Unspecified,
  enabled: Boolean = progress.isUnspecified,
  tint: Color = AppTheme.onBackgroundColor,
  shadow: Color = Color.Unspecified,
) {
  CompositionLocalProvider(
    LocalMinimumInteractiveComponentSize provides Dp.Unspecified,
  ) {
    MaterialIconButton(
      onClick = onClick,
      modifier = modifier
        .ovalShadow(shadow)
        .progressBorder(color = progress),
      enabled = enabled,
    ) {
      Icon(
        painter = painter,
        tint = tint,
      )
    }
  }
}
