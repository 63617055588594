package app.megachat.client.ui.design.user.boid

import androidx.compose.foundation.layout.Box
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.SubcomposeLayout
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Constraints
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.util.fastForEach
import app.megachat.client.ui.design.user.UserSummary
import app.megachat.client.ui.design.util.rememberUpdatedStateIfNotNull
import app.megachat.client.ui.design.util.toDp
import app.megachat.shared.base.data.UserId
import kotlinx.collections.immutable.ImmutableMap

@Composable
fun UserBoids(
  state: UserBoidsState,
  users: ImmutableMap<UserId, UserSummary>,
  onBoidClick: (UserId) -> Unit,
  modifier: Modifier = Modifier,
) {
  UserBoidsLayout(
    modifier = modifier,
    state = state,
    userBoid = { boidState ->
      rememberUpdatedStateIfNotNull(users[boidState.userId]).value?.let { user ->
        UserBoid(
          boidState = boidState,
          user = user,
          onClick = { onBoidClick(boidState.userId) },
        )
      }
    },
  )
}

@Composable
private fun UserBoidsLayout(
  state: UserBoidsState,
  userBoid: @Composable (UserBoidsState.UserBoid) -> Unit,
  modifier: Modifier = Modifier,
) {
  val density = LocalDensity.current

  SubcomposeLayout(
    modifier = modifier
      .onSizeChanged { state.updateBounds(it.toDp(density)) },
  ) { constraints ->
    val measured = state.boids
      .map { (userId, boidState) ->
        subcompose(userId) { Box(propagateMinConstraints = true) { userBoid(boidState) } }
          .single()
          .measure(
            Constraints.fixed(
              boidState.radius.times(2).roundToPx(),
              boidState.radius.times(2).roundToPx(),
            )
          ) to boidState
      }
    layout(constraints.maxWidth, constraints.maxHeight) {
      measured.fastForEach { (it, state) ->
        val offset = IntOffset(
          -it.width / 2,
          -it.height / 2,
        )
        it.placeWithLayer(
          position = offset,
          zIndex = state.center.z.value,
        ) {
          val scale = (state.center.z / UserBoidsState.MaxZ)
            .coerceIn(0f, 1f)
            .times(0.5f)
            .plus(0.6f)

          scaleX = scale
          scaleY = scale

          translationX = state.center.x.toPx()
          translationY = state.center.y.toPx()
        }
      }
    }
  }
}
