package app.megachat.client.ui.design.user.status

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.core.InfiniteRepeatableSpec
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.tween
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.scaleIn
import androidx.compose.animation.scaleOut
import androidx.compose.animation.togetherWith
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.drawscope.Stroke
import app.megachat.client.ui.design.theme.AppTheme

@Composable
fun OnlineStatusIndicator(
  onlineStatus: OnlineStatus?,
  modifier: Modifier = Modifier,
) {
  val errorColor = AppTheme.errorColor
  val offlineColor = AppTheme.offlineColor
  val awayColor = AppTheme.awayColor
  val onlineColor = AppTheme.onlineColor

  val pulseColor = AppTheme.offlineColor.copy(green = 1f)

  val transition = rememberInfiniteTransition("online status indicator")

  @Suppress("name_shadowing")
  AnimatedContent(
    modifier = modifier,
    targetState = onlineStatus,
    transitionSpec = { transitionSpec },
  ) { onlineStatus ->
    when (onlineStatus) {

      is OnlineStatus.Online -> {
        val pulse by transition.animateFloat(
          initialValue = 0f,
          targetValue = 1f,
          animationSpec = pulseAnimationSpec,
          label = "pulse",
        )

        Canvas(modifier = Modifier.clip(CircleShape)) {
          val size = size.minDimension
          check(size > 0) { "OnlineStatusIndicator must have a size modifier" }
          drawRect(onlineColor)
          drawCircle(
            pulseColor,
            radius = size * pulse * 0.775f,
            style = Stroke(width = size * (0.05f + pulse * 0.5f)),
          )
        }
      }

      is OnlineStatus.Away -> {
        Canvas(modifier = Modifier.clip(CircleShape)) {
          val size = size.minDimension
          check(size > 0) { "OnlineStatusIndicator must have a size modifier" }
          drawRect(awayColor)
          drawCircle(centerColor, radius = size * 0.2f)
        }
      }

      is OnlineStatus.Offline ->
        Canvas(modifier = Modifier.clip(CircleShape)) {
          val size = size.minDimension
          check(size > 0) { "OnlineStatusIndicator must have a size modifier" }
          drawRect(offlineColor)
          drawCircle(centerColor, radius = size * 0.2f)
        }

      is OnlineStatus.Unknown ->
        Canvas(modifier = Modifier.clip(CircleShape)) {
          val size = size.minDimension
          check(size > 0) { "OnlineStatusIndicator must have a size modifier" }
          drawRect(errorColor)
        }

      null -> Unit
    }
  }
}

private val centerColor = Color.White.copy(alpha = 0.5f)

private val transitionSpec = fadeIn() + scaleIn() togetherWith fadeOut() + scaleOut()

private val pulseAnimationSpec = InfiniteRepeatableSpec<Float>(
  animation = tween(
    durationMillis = 1_000,
    delayMillis = 500,
  ),
)
