package app.megachat.client.ui.core.circuit

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import app.megachat.client.ui.core.navigation.Destination
import app.megachat.client.ui.core.navigation.Destination.Presentation.FullScreen
import com.slack.circuit.backstack.NavArgument
import kotlinx.collections.immutable.ImmutableList
import com.slack.circuit.backstack.NavDecoration as CircuitNavDecoration

internal class NavDecoration(pop: () -> Unit) : CircuitNavDecoration {

  @Composable
  override fun <T : NavArgument> DecoratedContent(
    args: ImmutableList<T>,
    backStackDepth: Int,
    modifier: Modifier,
    content: @Composable (T) -> Unit,
  ) {
    // NOTE: topmost (visible) destination is at index zero.
    val fullScreenIndex = args.indexOfFirst {
      (it.screen as Destination).presentation is FullScreen
    }

    val opaqueArgs = args.subList(fullScreenIndex, args.size)
    opaque.DecoratedContent(
      args = opaqueArgs,
      backStackDepth = opaqueArgs.size,
      modifier = modifier.fillMaxSize(),
      content = content,
    )

    if (fullScreenIndex > 0) {
      val translucentArgs = args.subList(0, fullScreenIndex)
      translucent.DecoratedContent(
        args = translucentArgs,
        backStackDepth = translucentArgs.size,
        modifier = modifier.fillMaxSize(),
        content = content,
      )
    }
  }

  private val opaque = OpaqueNavDecoration()
  private val translucent = TranslucentNavDecoration(onHidden = pop)
}
