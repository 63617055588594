package app.megachat.client.ui.design.user

import androidx.compose.runtime.Immutable
import app.megachat.client.ui.design.avatar.AvatarStyle
import app.megachat.client.ui.design.custom.CustomBorder
import app.megachat.client.ui.design.custom.CustomBrush
import app.megachat.client.ui.design.custom.CustomEmote
import app.megachat.client.ui.design.custom.CustomFont
import app.megachat.client.ui.design.custom.CustomShape
import app.megachat.client.ui.design.media.ImageAsset
import app.megachat.client.ui.design.message.MessageStyle
import app.megachat.client.ui.design.text.TextStyle
import app.megachat.client.ui.design.user.relationship.UserRelationship
import app.megachat.client.ui.design.user.status.OnlineStatus
import app.megachat.shared.base.data.UserId
import app.megachat.shared.base.data.UserTag
import app.megachat.shared.base.util.emptyImmutableList
import app.megachat.shared.base.util.lazyPub
import kotlinx.collections.immutable.ImmutableList

@Immutable
data class UserSummary(
  val id: UserId,
  val profile: Profile?,
  val onlineStatus: OnlineStatus?,
  val relationship: UserRelationship?,
) {

  @Immutable
  data class Profile(
    val userTag: UserTag?,
    val displayName: String?,
    val displayNameStyle: TextStyle?,
    val customBorders: ImmutableList<CustomBorder>,
    val customBrushes: ImmutableList<CustomBrush>,
    val customFonts: ImmutableList<CustomFont>,
    val customShapes: ImmutableList<CustomShape>,
    val aboutMe: String?,
    val aboutMeStyle: TextStyle?,
    val avatarImage: ImageAsset?,
    val avatarStyle: AvatarStyle?,
    val backgroundImage: ImageAsset?,
    val messageStyle: MessageStyle?,
    val defaultEmote: CustomEmote?,
  ) {

    companion object {
      val EMPTY by lazyPub {
        Profile(
          userTag = null,
          displayName = null,
          displayNameStyle = null,
          customBorders = emptyImmutableList(),
          customBrushes = emptyImmutableList(),
          customFonts = emptyImmutableList(),
          customShapes = emptyImmutableList(),
          aboutMe = null,
          aboutMeStyle = null,
          avatarImage = null,
          avatarStyle = null,
          backgroundImage = null,
          messageStyle = null,
          defaultEmote = null,
        )
      }
    }
  }

  companion object {
    val EMPTY by lazyPub {
      UserSummary(
        id = UserId.EMPTY,
        profile = null,
        onlineStatus = null,
        relationship = null,
      )
    }
  }
}
