package app.megachat.client.ui.design.theme

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.automirrored.filled.ExitToApp
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Check
import androidx.compose.material.icons.filled.CheckCircle
import androidx.compose.material.icons.filled.Clear
import androidx.compose.material.icons.filled.Close
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material.icons.filled.Email
import androidx.compose.material.icons.filled.MoreVert
import androidx.compose.material.icons.filled.Person
import androidx.compose.material.icons.filled.Search
import androidx.compose.material.icons.filled.Settings
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.rememberVectorPainter
import app.megachat.client.ui.design.material.icons.filled.AutoAwesome
import app.megachat.client.ui.design.material.icons.filled.Block
import app.megachat.client.ui.design.material.icons.filled.Bunny
import app.megachat.client.ui.design.material.icons.filled.Cancel
import app.megachat.client.ui.design.material.icons.filled.Circle
import app.megachat.client.ui.design.material.icons.filled.Colorize
import app.megachat.client.ui.design.material.icons.filled.People
import app.megachat.client.ui.design.material.icons.filled.StopCircle
import app.megachat.client.ui.design.material.icons.filled.SwitchAccount
import app.megachat.client.ui.design.material.icons.filled.TrendingUp
import app.megachat.client.ui.design.material.icons.filled.Visibility
import app.megachat.client.ui.design.material.icons.filled.VisibilityOff
import app.megachat.client.ui.design.material.icons.outlined.PauseCircle
import app.megachat.client.ui.design.material.icons.outlined.PlayCircle

object Icons {

  object Common {

    val Add: Painter
      @Composable get() = Add_

    val CaretEnd: Painter
      @Composable get() = CaretEnd_

    val CaretStart: Painter
      @Composable get() = CaretStart_

    val Chat: Painter
      @Composable get() = Chat_

    val Check: Painter
      @Composable get() = Check_

    val Clear: Painter
      @Composable get() = Clear_

    val Delete: Painter
      @Composable get() = Delete_

    val Dropper: Painter
      @Composable get() = Colorize_

    val Email: Painter
      @Composable get() = Email_

    val Environments: Painter
      @Composable get() = Bunny_

    val Games: Painter
      @Composable get() = Games_

    val Gif: Painter
      @Composable get() = Gif_

    val Invisible: Painter
      @Composable get() = VisibilityOff_

    val More: Painter
      @Composable get() = MoreVert_

    val Pencil: Painter
      @Composable get() = Edit_

    val Plus: Painter
      @Composable get() = Plus_

    val Remove: Painter
      @Composable get() = Remove_

    val Restore: Painter
      @Composable get() = Restore_

    val ScrollDown: Painter
      @Composable get() = ScrollDown_

    val Search: Painter
      @Composable get() = Search_

    val Settings: Painter
      @Composable get() = Settings_

    val SignOut: Painter
      @Composable get() = ExitToApp_

    val Sparkles: Painter
      @Composable get() = AutoAwesome_

    val TextStyle: Painter
      @Composable get() = TextStyle_

    val Trending: Painter
      @Composable get() = TrendingUp_

    val Visible: Painter
      @Composable get() = Visibility_

    val Wallpaper: Painter
      @Composable get() = Wallpaper_

    val WallpaperNone: Painter
      @Composable get() = WallpaperNone_
  }

  object Navigation {

    val Back: Painter
      @Composable get() = ArrowBack_

    val Close: Painter
      @Composable get() = Close_
  }

  object Chat {

    val Avatar: Painter
      @Composable get() = AccountCircle_

    val AvatarDefault: Painter
      @Composable get() = Workspaces_

    val MemberBan: Painter
      @Composable get() = MemberBan_

    val MemberDemote1: Painter
      @Composable get() = MemberDemote1_

    val MemberDemote2: Painter
      @Composable get() = MemberDemote2_

    val MemberMinus: Painter
      @Composable get() = MemberMinus_

    val MemberPlus: Painter
      @Composable get() = MemberPlus_

    val MemberPromote1: Painter
      @Composable get() = MemberPromote1_

    val MemberPromote2: Painter
      @Composable get() = MemberPromote2_

    val MemberPromote3: Painter
      @Composable get() = MemberPromote3_

    val MemberSearch: Painter
      @Composable get() = MemberSearch_

    val Send: Painter
      @Composable get() = Send_
  }

  object Games {

    val NextTurn: Painter
      @Composable get() = NextTurn_

    val TicTacToeO: Painter
      @Composable get() = TicTacToeO_

    val TicTacToeX: Painter
      @Composable get() = TicTacToeX_
  }

  object User {

    val Block: Painter
      @Composable get() = Block_

    val Friend: Painter
      @Composable get() = Person_

    val Friends: Painter
      @Composable get() = Friends_

    val Ignore: Painter
      @Composable get() = PauseCircle_

    val Impersonate: Painter
      @Composable get() = SwitchAccount_

    val Befriend: Painter
      @Composable get() = Befriend_

    val Relationships: Painter
      @Composable get() = People_

    val Search: Painter
      @Composable get() = UserSearch_

    val Unblock: Painter
      @Composable get() = CheckCircle_

    val Unfriend: Painter
      @Composable get() = Unfriend_

    val Unignore: Painter
      @Composable get() = PlayCircle_

    val Uninvite: Painter
      @Composable get() = Cancel_
  }

  internal val AccountCircle_: Painter
    @Composable get() =  rememberVectorPainter(app.megachat.client.ui.design.theme.icons.AccountCircle)

  internal val Add_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Add)

  internal val ArrowBack_: Painter
    @Composable get() = rememberVectorPainter(Icons.AutoMirrored.Filled.ArrowBack)

  internal val AutoAwesome_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.AutoAwesome)

  internal val Befriend_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Befriend)

  internal val Block_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Block)

  internal val Bunny_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Bunny)

  internal val Cancel_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Cancel)

  internal val CaretEnd_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.CaretEnd)

  internal val CaretStart_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.CaretStart)

  internal val Chat_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Chat)

  internal val Check_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Check)

  internal val CheckCircle_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.CheckCircle)

  internal val Circle: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Circle)

  internal val Clear_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Clear)

  internal val Close_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Close)

  internal val Colorize_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Colorize)

  internal val Delete_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Delete)

  internal val Edit_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Edit)

  internal val Email_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Email)

  internal val ExitToApp_: Painter
    @Composable get() = rememberVectorPainter(Icons.AutoMirrored.Filled.ExitToApp)

  internal val Friends_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Friends)

  internal val Games_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Games)

  internal val Gif_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Gif)

  internal val MemberBan_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.MemberBan)

  internal val MemberDemote1_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.MemberDemote1)

  internal val MemberDemote2_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.MemberDemote2)

  internal val MemberMinus_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.MemberMinus)

  internal val MemberPlus_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.MemberPlus)

  internal val MemberPromote1_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.MemberPromote1)

  internal val MemberPromote2_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.MemberPromote2)

  internal val MemberPromote3_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.MemberPromote3)

  internal val MemberSearch_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.MemberSearch)

  internal val MoreVert_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.MoreVert)

  internal val NextTurn_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.NextTurn)

  internal val People_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.People)

  internal val Person_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Person)

  internal val PauseCircle_: Painter
    @Composable get() = rememberVectorPainter(Icons.Outlined.PauseCircle)

  internal val PlayCircle_: Painter
    @Composable get() = rememberVectorPainter(Icons.Outlined.PlayCircle)

  internal val Plus_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Plus)

  internal val Remove_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Remove)

  internal val Restore_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Restore)

  internal val ScrollDown_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.ScrollDown)

  internal val Search_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Search)

  internal val Send_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Send)

  internal val Settings_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Settings)

  internal val StopCircle_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.StopCircle)

  internal val SwitchAccount_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.SwitchAccount)

  internal val TextStyle_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.TextStyle)

  internal val TicTacToeO_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.TicTacToeO)

  internal val TicTacToeX_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.TicTacToeX)

  internal val TrendingUp_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.TrendingUp)

  internal val Unfriend_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Unfriend)

  internal val UserSearch_: Painter
    @Composable get() = rememberVectorPainter(app.megachat.client.ui.design.theme.icons.UserSearch)

  internal val Visibility_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.Visibility)

  internal val VisibilityOff_: Painter
    @Composable get() = rememberVectorPainter(Icons.Filled.VisibilityOff)

  internal val Wallpaper_: Painter
    @Composable get() =  rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Wallpaper)

  internal val WallpaperNone_: Painter
    @Composable get() =  rememberVectorPainter(app.megachat.client.ui.design.theme.icons.WallpaperNone)

  internal val Workspaces_: Painter
    @Composable get() =  rememberVectorPainter(app.megachat.client.ui.design.theme.icons.Workspaces)
}
